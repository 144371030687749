import React, { useEffect, useState } from "react";
import "./Dashboard.css";

const TimelineSlider = ({ startDate, endDate }) => {
  const [sliderValue, setSliderValue] = useState(0);

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate).getTime();
      const end = new Date(endDate).getTime();
      const today = Date.now();

      // Calculate the slider position based on today's date
      const value =
        today < start
          ? 0
          : Math.min(((today - start) / (end - start)) * 100, 100);

      setSliderValue(value);
    }
  }, [startDate, endDate]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="timeline-slider">
      <div className="slider-header">
        <span>{startDate ? formatDate(startDate) : "Start Date"}</span>
        <span>{endDate ? formatDate(endDate) : "End Date"}</span>
      </div>
      <div className="slider-bar">
        <div
          className="slider-progress"
          style={{ width: `${sliderValue}%` }}
        ></div>
      </div>
      <p className="current-date">
        Current Date: {formatDate(new Date())} {/* Always show today's date */}
      </p>
    </div>
  );
};

export default TimelineSlider;
