import React, { useEffect, useState, useRef } from "react";
import "./Dashboard.css";

const FilterDropdown = ({ campaignsDataList = [], onCampaignSelect }) => {
  const [searchTerm, setSearchTerm] = useState(""); // Tracks the input value
  const [filteredCampaigns, setFilteredCampaigns] = useState(campaignsDataList);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref for the dropdown container

  useEffect(() => {
    setFilteredCampaigns(
      campaignsDataList.filter((campaign) =>
        campaign.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, campaignsDataList]);

  const handleCampaignSelect = (campaign) => {
    setSearchTerm(campaign.name); // Set the selected campaign name as the input value
    onCampaignSelect(campaign || null);
    setIsDropdownOpen(false); // Close the dropdown
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown if click is outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="filter-dropdown" ref={dropdownRef}>
      <div style={{ width: "100%", marginRight: "10px", marginTop: "15px" }}>
        Filter Campaign
      </div>
      <input
        type="text"
        className="campaign-search-input"
        placeholder="Search campaign..."
        value={searchTerm}
        onFocus={() => setIsDropdownOpen(true)} // Open dropdown on focus
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
      />
      {isDropdownOpen && (
        <div className="campaign-list-dropdown">
          {filteredCampaigns.map((campaign) => (
            <div
              key={campaign.id}
              onClick={() => handleCampaignSelect(campaign)}
              className="campaign-item"
            >
              {campaign.name}
            </div>
          ))}
          {filteredCampaigns.length === 0 && (
            <div className="no-results">No campaigns found</div>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
