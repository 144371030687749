import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getCampaignList,
  getCampaignGraphs,
} from "redux/features/business/businessSlice";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";
import "./Dashboard.css";
import { unwrapResult } from "@reduxjs/toolkit";
import { getDashboardUserDataAPI } from "redux/features/authentication/authSlice";
import StatusBar from "./../campaigns/CampaignsStatusBar";

import FilterDropdown from "./FilterDropdown";

import TimelineSlider from "./TimelineSlider";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

const Dashboard = () => {
  const dispatch = useDispatch();
  const [campaignDataList, setCampaignDataList] = useState(null);
  const [campaignData, setCampaignData] = useState(null);

  const [campaignsDataList, setCampaignsDataList] = useState(null);
  const [campaignSelect, setCampaignSelect] = useState(null);

  const [timeFilter, setTimeFilter] = useState("all-time");

  useEffect(() => {
    dispatch(getDashboardUserDataAPI())
      .then((result) => {
        const userId = result.payload.response.id;
        return dispatch(getCampaignList(userId)).then(unwrapResult);
      })
      .then((res) => {
        const filteredCampaigns = res.campaigns.map(
          ({ id, campaign_name }) => ({
            id,
            name: campaign_name,
          })
        );
        setCampaignsDataList(filteredCampaigns);
      })
      .catch((error) => {
        console.error("Error fetching campaigns:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    if (campaignSelect) {
      dispatch(getCampaignGraphs(campaignSelect.id))
        .then(unwrapResult)
        .then((result) => {
          const campaignResults = result.campaignResults;

          // Extract datasets
          const returningPlayers = campaignResults[0];
          const newUniquePlayers = campaignResults[1];
          const uniquePlayers = campaignResults[2];
          const gamesPlayed = campaignResults[3];

          // Normalize data to use the same date key
          const normalizeDates = (data, dateKey = "date") =>
            data.map((item) => ({
              ...item,
              date: item[dateKey],
            }));

          const normalizedNewUniquePlayers = normalizeDates(
            newUniquePlayers,
            "dates"
          );

          // Collect all unique dates across datasets
          const allDates = new Set([
            ...returningPlayers.map((item) => item.date),
            ...normalizedNewUniquePlayers.map((item) => item.date),
            ...uniquePlayers.map((item) => item.date),
            ...gamesPlayed.map((item) => item.date),
          ]);

          // Combine data for each date
          const combinedData = Array.from(allDates)
            .sort() // Sort dates chronologically
            .map((date) => ({
              date,
              returning_players:
                returningPlayers.find((item) => item.date === date)
                  ?.returning_players || 0,
              new_unique_players:
                normalizedNewUniquePlayers.find((item) => item.date === date)
                  ?.new_unique_players || 0,
              unique_players:
                uniquePlayers.find((item) => item.date === date)
                  ?.unique_players || 0,
              games_played:
                gamesPlayed.find((item) => item.date === date)?.games_played ||
                0,
            }));

          // Update state
          setCampaignDataList(combinedData);
          setCampaignData(...campaignResults[4]);
        })
        .catch((error) => {
          console.error("Error fetching campaign graphs:", error);
        });
    }
  }, [campaignSelect, dispatch]);

  const getFilteredData = () => {
    if (!campaignDataList) return [];

    const currentDate = new Date();
    const startOfWeek = new Date(
      currentDate.setDate(currentDate.getDate() - currentDate.getDay())
    );
    const endOfWeek = new Date(currentDate.setDate(currentDate.getDate() + 6));

    switch (timeFilter) {
      case "today":
        return campaignDataList.filter(
          (data) => data.date === new Date().toISOString().split("T")[0]
        );
      case "this-week":
        return campaignDataList.filter((data) => {
          const date = new Date(data.date);
          return date >= startOfWeek && date <= endOfWeek;
        });
      case "this-month":
        return campaignDataList.filter((data) => {
          const date = new Date(data.date);
          return (
            date.getMonth() === currentDate.getMonth() &&
            date.getFullYear() === currentDate.getFullYear()
          );
        });
      case "all-time":
      default:
        return campaignDataList;
    }
  };

  const filteredData = getFilteredData();
  const dates = filteredData.map((item) => item.date);
  const returningPlayers = filteredData.map((item) =>
    Number(item.returning_players || 0)
  );
  const uniquePlayers = filteredData.map((item) =>
    Number(item.unique_players || 0)
  );
  const gamesPlayed = filteredData.map((item) =>
    Number(item.games_played || 0)
  );

  const newUniquePlayers = filteredData.map((item) =>
    Number(item.new_unique_players || 0)
  );

  const activityData = {
    labels: dates,
    datasets: [
      {
        label: "Game Registrations",
        data: uniquePlayers,
        borderColor: "#28c2d6",
        backgroundColor: "#28c2d6",
        borderWidth: 2,
        hidden: uniquePlayers.length === 0, // Hide if no data
      },
      {
        label: "Returning Players",
        data: returningPlayers,
        borderColor: "#fec053",
        backgroundColor: "#fec053",
        borderWidth: 2,
        hidden: returningPlayers.length === 0, // Hide if no data
      },
      {
        label: "Games Played",
        data: gamesPlayed,
        borderColor: "#5e84e7",
        backgroundColor: "#5e84e7",
        borderWidth: 2,
        hidden: gamesPlayed.length === 0, // Hide if no data
      },
      {
        label: "New Unique Players",
        data: newUniquePlayers,
        borderColor: "#34c759", // Green color
        backgroundColor: "#34c759",
        borderWidth: 2,
        hidden: newUniquePlayers.length === 0, // Hide if no data
      },
    ],
  };

  // Dynamically calculate stats

  // Example - Replace these with actual data from API if available

  console.log(campaignData);

  const totalDurationInSeconds = campaignData?.total_duration || 0;
  const totalUniquePlayers = campaignData?.players_registered || 0; // Default to 1 to avoid division by zero

  const totalReturningPlayers = campaignData?.players_returned
    ? parseInt(campaignData.players_returned).toLocaleString()
    : "0";

  const totalGamesPlayed = campaignData?.rounds_finished
    ? parseInt(campaignData.rounds_finished).toLocaleString()
    : "0";

  // Calculate average duration per user
  const avgDurationInSeconds =
    totalUniquePlayers > 0 ? totalDurationInSeconds / totalUniquePlayers : 0;

  const avgTimePerUser = `${Math.floor(
    avgDurationInSeconds / 60
  )} min ${Math.floor(avgDurationInSeconds % 60)} sec`;

  // Calculate total time played
  const totalTimePlayed = `${Math.floor(
    totalDurationInSeconds / 3600
  )} hrs ${Math.floor((totalDurationInSeconds % 3600) / 60)} min ${Math.floor(
    totalDurationInSeconds % 60
  )} sec`;

  const totalVisitsToGameWindow = campaignData?.camp_engaged
    ? parseInt(campaignData.camp_engaged).toLocaleString()
    : "0";

  const visitToRegistrationRate =
    campaignData?.players_registered && campaignData?.all_registrations
      ? (
          (campaignData.players_registered / campaignData.all_registrations) *
          100
        ).toFixed(0) + "%"
      : "0%";

  const playersReturnRate =
    campaignData?.players_registered && campaignData?.players_returned
      ? (
          (campaignData.players_returned / campaignData.players_registered) *
          100
        ).toFixed(0) + "%"
      : "0%";

  const gameCompletionRate =
    campaignData?.rounds_started && campaignData?.rounds_finished
      ? (
          (campaignData.rounds_finished / campaignData.rounds_started) *
          100
        ).toFixed(0) + "%"
      : "0%";

  const campaignName = campaignData?.campaign_name || "No Campaign";

  const handleCampaignChange = (event) => {
    const selectedId = event.target.value;
    setCampaignSelect(
      campaignsDataList.find((campaign) => campaign.id === selectedId) || null
    );
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <h1>Results Dashboard</h1>
        <div className="dashboard-status">
          <p style={{ margin: 0 }}>{campaignName}</p>
          <StatusBar
            status={campaignData?.status}
            end={campaignData?.campaign_end}
          />
        </div>
      </header>

      <section className="filters-timeline">
        <FilterDropdown
          campaignsDataList={campaignsDataList || []}
          onCampaignSelect={(campaign) => setCampaignSelect(campaign)}
        />

        {campaignData?.campaign_start && campaignData?.campaign_end && (
          <TimelineSlider
            startDate={campaignData.campaign_start}
            endDate={campaignData.campaign_end}
          />
        )}
      </section>

      <section className="stats-cards">
        <div className="stat-card">
          <p className="info-name"> Game registrations </p>
          <p className="additional-info">All Unique players</p>
          <p className="stat-number">{totalUniquePlayers}</p>
        </div>
        <div className="stat-card">
          <p className="info-name">Returning players</p>
          <p className="additional-info"></p>
          <p className="stat-number">{totalReturningPlayers}</p>
        </div>
        <div className="stat-card">
          <p className="info-name">Total games played</p>
          <p className="additional-info"></p>
          <p className="stat-number">{totalGamesPlayed}</p>
        </div>
        <div className="stat-card">
          <p className="info-name">Avg. time per user playing</p>
          <p className="additional-info"></p>
          <p className="stat-number">{avgTimePerUser}</p>
        </div>
        <div className="stat-card">
          <p className="info-name">Total time played</p>
          <p className="additional-info"> </p>
          <p className="stat-number">{totalTimePlayed}</p>
        </div>
      </section>

      <div className="section-holder">
        <section className="activity-analysis">
          <div className="activity-header">
            <h2>Activity Analysis</h2>
            <div className="time-filter">
              <label style={{ margin: "0px" }}>Sort by:</label>
              <select
                className="custom-select"
                onChange={(e) => setTimeFilter(e.target.value)}
                value={timeFilter}
              >
                <option value="all-time">All Time</option>
                <option value="this-month">This Month</option>
                <option value="this-week">This Week</option>
                <option value="today">Today</option>
              </select>
            </div>
          </div>
          <Line data={activityData} options={{ maintainAspectRatio: false }} />
        </section>

        <section className="yellow-metrics">
          <div className="metric-card">
            <p className="metric-number-name">Total visits to game window</p>
            <p className="metric-number">{totalVisitsToGameWindow}</p>
          </div>
          <div className="metric-card">
            <p className="metric-number-name">Visit to registration rate</p>
            <p className="metric-number">{visitToRegistrationRate}</p>
          </div>
          <div className="metric-card">
            <p className="metric-number-name">Players return rate</p>
            <p className="metric-number">{playersReturnRate}</p>
          </div>
          <div className="metric-card">
            <p className="metric-number-name">Game completion rate</p>
            <p className="metric-number">{gameCompletionRate}</p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Dashboard;
